exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-abb-js": () => import("./../../../src/pages/work/abb.js" /* webpackChunkName: "component---src-pages-work-abb-js" */),
  "component---src-pages-work-index-js": () => import("./../../../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */),
  "component---src-pages-work-macgregor-js": () => import("./../../../src/pages/work/macgregor.js" /* webpackChunkName: "component---src-pages-work-macgregor-js" */),
  "component---src-pages-work-vare-js": () => import("./../../../src/pages/work/vare.js" /* webpackChunkName: "component---src-pages-work-vare-js" */),
  "component---src-pages-work-various-js": () => import("./../../../src/pages/work/various.js" /* webpackChunkName: "component---src-pages-work-various-js" */)
}

